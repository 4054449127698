import APP_UTILITIES from '@/utilities/commonFunctions';
import { Component, Prop, Vue } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import attendanceStore from '@/store/modules/attendanceStore';

@Component
export default class AttendanceDropdownComponent extends Vue {
    //public search : any = {firstName: "", lastName: "", Transportation: "", Attendance:""};
    public attendacneStatusList: Array<string> = [];
    // public checkOutAttendacneStatusList: Array<string> = APP_CONST.CHECKOUT_ATTENDANCE_STATUS;
    public ascending : string = '';
    public statusValue : string = '';
    public isSearching: boolean = false;
    public filteredSearchField: string = ''
    public filteredSearchValue: string = '';
    public filteredIsExactMatch: boolean = false;
    public filteredValue: string = '';

    @Prop()
    headers!: any;

    @Prop()
    tabSelected!: string

    @Prop()
    tabSelectedValue!: string

    @Prop()
    scholarSearchValue!: string;

    @Prop()
    firstNameSorted!: boolean

    @Prop()
    lastNameSorted!: boolean

    @Prop({ default: false })
    gradeSorted!: boolean;

    @Prop()
    currentSortedColumn!: string

    @Prop()
    currentSortOrder!: string

    @Prop()
    transportationSorted!: boolean

    @Prop()
    weekSort!: any

    @Prop()
    currentSearchingDay!: string

    @Prop()
    searchValue!: string

    @Prop()
    searchField! :string

    /* istanbul ignore next */
    get search(){
        return attendanceStore.attendanceSearchData
    }

    /* istanbul ignore next */
    get attedanceList() {
        if (this.filteredIsExactMatch){
            let selectElement: any = this.attendacneStatusList.filter((value: any) => { return value == this.filteredValue});
            let unselectElement: any = [];
            this.attendacneStatusList.forEach((value: any) => {
                if (value != this.search.Attendance) {
                    unselectElement.push(value)
                }
            })
            let searchInputValues = unselectElement.filter((el: string) => {
                return `${el}`.toLowerCase().includes(this.search.Attendance.toLowerCase())
            })
            let index = searchInputValues.findIndex((val: any)=>{return val==selectElement[0]});
            return index>=0 ? searchInputValues : selectElement.concat(searchInputValues);
        }
        else {
            return this.attendacneStatusList.filter((el: string) => {
                return `${el}`.toLowerCase().includes(this.search.Attendance.toLowerCase())
            })
        }
    }

    mounted(){
        this.attendacneStatusList = this.tabSelected=='Check-in'? APP_CONST.ATTENDANCE_STATUS : APP_CONST.CHECKOUT_ATTENDANCE_STATUS;
        if(this.tabSelected == 'Check-in'){
            this.matchDropdownValues();
        }
    }

    destroyed() {
        if (this.filteredSearchField) {
            this.search[this.searchField] = this.filteredSearchValue;
        }
    }

    beforeMount(){
        this.getSearchedData()
        this.filteredValue=this.search[this.searchField];
        if (this.filteredIsExactMatch) {
            this.search[this.searchField]='';
        }
        attendanceStore.mutateAttendanceSearch(this.search);
    }

    matchDropdownValues(){
        if(!this.filteredIsExactMatch){
            if(this.currentSearchingDay == this.headers.day && this.searchField == 'Attendance'){
                let tempSearch = JSON.parse(JSON.stringify(this.search))
                tempSearch['Attendance'] = this.searchValue;
                attendanceStore.mutateAttendanceSearch(tempSearch);
            } else if(this.currentSearchingDay != this.headers.day){
                let tempSearch = JSON.parse(JSON.stringify(this.search))
                tempSearch['Attendance'] = '';
                attendanceStore.mutateAttendanceSearch(tempSearch);
            }
                        
        }
    }
    
    searchAttendanceData(searchField: string,searchValue: string, dayItem?: any, isExactMatch?: boolean, fromClearSelection?: boolean){
        isExactMatch = isExactMatch ? isExactMatch : false;
        fromClearSelection = fromClearSelection ? fromClearSelection : false;
        let tempSearch = JSON.parse(JSON.stringify(this.search))
        for (var key in tempSearch) {
            if (tempSearch.hasOwnProperty(key) && (key !== searchField || key !== 'isExactMatch')) {
                tempSearch[key] = "";
            }
        }
        attendanceStore.mutateAttendanceSearch(tempSearch);
        let searchData = {searchField :searchField, searchValue :searchValue, dayItem:dayItem, isExactMatch: isExactMatch, fromClearSelection: fromClearSelection}
        if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day'){
            attendanceStore.mutateCheckInSearchingPersistance(searchData)
        } else if(this.tabSelected == 'Check-out'){
            attendanceStore.mutateCheckOutSearchingPersistance(searchData)
        } else if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week'){
            attendanceStore.mutateCheckInWeekSearchingPersistance(searchData)
        }
        this.search[searchField]= searchData.searchValue || '';
        this.filteredSearchValue = searchData.searchValue || '';
        this.$emit('searchAttendance',searchData)
    }

    resetSearch(field:string, dayItem?: any){
        if (!this.search[field].length) {
            this.searchAttendanceData('','', dayItem);
        }
        this.filteredSearchField = '';
        this.filteredSearchValue = '';
    }

    clearSelection() {
        this.search[this.searchField]= '';
        this.filteredSearchValue = '';
        this.searchAttendanceData('', '', false, false, true);
     }

    sortAttendance(sortField: string, sortDirection:any, dayItem?: any){ 
        let sortData = {sortField:sortField, sortDirection:sortDirection, dayItem: dayItem}
        this.$emit("sortAttendanceData", sortData);
        if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day'){
            attendanceStore.mutateCheckInSortingPersistance(sortData)
        } else if(this.tabSelected == 'Check-out'){
            attendanceStore.mutateCheckOutSortingPersistance(sortData)
        } else if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week'){
            attendanceStore.mutateCheckInWeekSortingPersistance(sortData)
        }
    }

    selectSearchAttendacne(searchField: string, searchValue: string, headers: any){ 
        let tempSearch = JSON.parse(JSON.stringify(this.search))
        tempSearch[searchField] = searchValue;
        tempSearch['isExactMatch'] = true;
        attendanceStore.mutateAttendanceSearch(tempSearch);
        this.searchAttendanceData(searchField, searchValue, headers, true);
    }

    getSearchedData(){ 
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        let filteredSearchData : any = {};
        
        if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day'){
            if(localStorage.getItem('CheckinSearchingData')){
                filteredSearchData = localStorage.getItem('CheckinSearchingData');
                if(filteredSearchData != ''){
                  filteredSearchData = JSON.parse(filteredSearchData);
                  if(filteredSearchData.hasOwnProperty([programId])){
                    if(filteredSearchData[programId].hasOwnProperty([siteId])){
                        this.filteredSearchField = filteredSearchData[programId][siteId].searchField
                        this.filteredSearchValue = filteredSearchData[programId][siteId].searchValue
                        this.filteredIsExactMatch = filteredSearchData[programId][siteId].isExactMatch
                    }
                  }
                }
              }
            /* istanbul ignore else */
            if(!filteredSearchData.hasOwnProperty([programId]) || !filteredSearchData[programId].hasOwnProperty([siteId])){
            this.filteredSearchField = '';
            this.filteredSearchValue = ''
            this.filteredIsExactMatch = false;
            }
        } else if(this.tabSelected == 'Check-out'){
            if(localStorage.getItem('CheckOutSearchingData')){
                filteredSearchData = localStorage.getItem('CheckOutSearchingData');
                if(filteredSearchData != ''){
                  filteredSearchData = JSON.parse(filteredSearchData);
                  if(filteredSearchData.hasOwnProperty([programId])){
                    if(filteredSearchData[programId].hasOwnProperty([siteId])){
                        this.filteredSearchField = filteredSearchData[programId][siteId].searchField
                        this.filteredSearchValue = filteredSearchData[programId][siteId].searchValue
                        this.filteredIsExactMatch = filteredSearchData[programId][siteId].isExactMatch
                    }
                  }
                }
              }
            /* istanbul ignore else */
            if(!filteredSearchData.hasOwnProperty([programId]) || !filteredSearchData[programId].hasOwnProperty([siteId])){
                this.filteredSearchField = '';
                this.filteredSearchValue = '';
                this.filteredIsExactMatch = false;
            }
        } else if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week'){
            if(localStorage.getItem('CheckinWeekSearchingData')){
                filteredSearchData = localStorage.getItem('CheckinWeekSearchingData');
                if(filteredSearchData != ''){
                  filteredSearchData = JSON.parse(filteredSearchData);
                  if(filteredSearchData.hasOwnProperty([programId])){
                    if(filteredSearchData[programId].hasOwnProperty([siteId])){
                        this.filteredSearchField = filteredSearchData[programId][siteId].searchField
                        this.filteredSearchValue = filteredSearchData[programId][siteId].searchValue
                        this.filteredIsExactMatch = filteredSearchData[programId][siteId].isExactMatch
                    }
                  }
                }
              }
            /* istanbul ignore else */
            if(!filteredSearchData.hasOwnProperty([programId]) || !filteredSearchData[programId].hasOwnProperty([siteId])){
                this.filteredSearchField = '';
                this.filteredSearchValue = '';
                this.filteredIsExactMatch = false;
            }
        }
        for (let key in this.search) {
            if (key == this.filteredSearchField) {
                this.search[key] = this.filteredSearchValue ? this.filteredSearchValue : '';
            } else{
                this.search[key] = ''
            }
        }
    }
}
